export const routes = [
    {
        type: 'enterprise',
        path: '/',
        component: () => import('$src/views/enterprise/Enterprise.vue'),
        children: [
            {
                path: 'qcp',
                title: 'QCP',
                icon: ['fas', 'sitemap'],
                component: () => import('$src/views/enterprise/qcps/QcpAll.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/qcps/QcpView.vue')
                    }
                ]
            },
            {
                hide: true,
                path: 'users',
                title: 'Users',
                component: () => import('$src/views/enterprise/users/Users.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/users/User.vue')
                    }
                ]
            }
        ]
    },
    {
        type: 'admin',
        path: '/',
        component: () => import('$src/views/admin/Admin.vue'),
        children: [
            {
                path: 'templates',
                title: 'Templates',
                icon: ['fas', 'sitemap'],
                component: () => import('$src/views/admin/templates/Templates.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/admin/templates/TemplatesView.vue')
                    },
                    {
                        path: 'qcps/:id',
                        component: () => import('$src/views/enterprise/qcps/QcpView.vue')
                    }
                ]
            },
            {
                path: 'qcps',
                title: 'QCPs',
                icon: ['fas', 'list-check'],
                component: () => import('$src/views/admin/adminQcps/AdminQcpAll.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/qcps/QcpView.vue')
                    },
                ]
            }
        ]
    },
]